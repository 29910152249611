import { ApiAuthType } from '@pib/api';
export const getLoginUrl = (config) => {
    var _a;
    const redirectUri = config.auth.redirectUrl;
    // For local development only
    // const redirectUri = 'https://localhost:3000/login';
    if (config.auth.type === ApiAuthType.ActiveDirectoryFederationServices) {
        const params = new URLSearchParams();
        params.append('response_type', 'code');
        params.append('client_id', config.auth.clientId);
        params.append('resource', config.auth.resource);
        params.append('redirect_uri', redirectUri);
        if (config.auth.scopes) {
            params.append('scope', config.auth.scopes);
        }
        return `${config.auth.authorizeUrl}?${params.toString()}`;
    }
    if (config.auth.type === ApiAuthType.AzureActiveDirectory ||
        config.auth.type === ApiAuthType.Okta) {
        const params = new URLSearchParams();
        params.append('response_type', 'code');
        params.append('client_id', config.auth.clientId);
        params.append('redirect_uri', redirectUri);
        params.append('response_mode', 'query');
        if ((_a = config.auth.scopes) === null || _a === void 0 ? void 0 : _a.length) {
            params.append('scope', config.auth.scopes);
        }
        if (config.auth.type === ApiAuthType.Okta) {
            params.append('state', config.auth.clientId);
        }
        return `${config.auth.authorizeUrl}?${params.toString()}`;
    }
    throw Error('Unsupported auth type');
};
