import { lazy, Suspense } from 'react';
import { Redirect, useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import UnauthorizedPage from 'pages/http/401/UnauthorizedPage';
import ForbiddenPage from 'pages/http/403/ForbiddenPage';
import NotFoundPage from 'pages/http/404/NotFoundPage';

import { Footer, Header, withAuth } from 'common';
import { LoadingLayout, LoadingLogin } from 'common/Loading';

const AsyncLogin = lazy(
  () =>
    import(/* webpackChunkName: "login" */ '../pages/login/login/LoginView'),
);
const AsyncEmployeeDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "employee-dtl" */ '../pages/employeeDetail/EmployeeDetailView'
    ),
);
const AsyncFlexplan = lazy(
  () =>
    import(/* webpackChunkName: "flexplan" */ '../pages/flexplan/FlexplanView'),
);
const AsyncMeetings = lazy(
  () =>
    import(
      /* webpackChunkName: "meeting-overview" */ '../pages/meeting/meetings/MeetingsView'
    ),
);
const AsyncMeetingsCreate = lazy(
  () =>
    import(
      /* webpackChunkName: "meeting-create" */ '../pages/meeting/create/CreateMeetingView'
    ),
);
const AsyncWizard = lazy(
  () => import(/* webpackChunkName: "wizard" */ '../pages/wizard/WizardView'),
);
const AsyncExplainAmbucare = lazy(
  () =>
    import(
      /* webpackChunkName: "explain-ambucare" */ '../pages/flexplanStatic/toelichting-ambucare'
    ),
);
const AsyncManualAmbucare = lazy(
  () =>
    import(
      /* webpackChunkName: "manual-ambucare" */ '../pages/flexplanStatic/handleiding-ambucare'
    ),
);
const AsyncProfelFamily = lazy(
  () =>
    import(
      /* webpackChunkName: "manual-ambucare" */ '../pages/flexplanStatic/profel-family-info'
    ),
);
const AsyncExplainPension = lazy(
  () =>
    import(
      /* webpackChunkName: "explain-pension" */ '../pages/flexplanStatic/toelichting-pensioensparen'
    ),
);
const AsyncContact = lazy(
  () =>
    import(/* webpackChunkName: "contact" */ '../pages/contact/ContactView'),
);
const AsyncDasboard = lazy(
  () =>
    import(
      /* webpackChunkName: "contact" */ '../pages/dashboard/DashboardView'
    ),
);

export default () => {
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Suspense
        fallback={
          pathname.includes('login') ? <LoadingLogin /> : <LoadingLayout />
        }
      >
        <Switch>
          <Route path="/401" exact component={UnauthorizedPage} />
          <Route path="/403" exact component={ForbiddenPage} />
          <Route path="/404" exact component={NotFoundPage} />
          <Route exact path="/login" component={AsyncLogin} />
          <Route exact path="/dashboard" component={AsyncDasboard} />
          <Route
            exact
            path="/info/:groupName?"
            component={withAuth(AsyncEmployeeDetail)}
          />
          <Route exact path="/afspraken" component={withAuth(AsyncMeetings)} />
          <Route
            exact
            path="/afspraken/aanmaken"
            component={withAuth(AsyncMeetingsCreate)}
          />
          <Route exact path="/contact" component={withAuth(AsyncContact)} />
          <Route
            exact
            path="/wizard/:policyId"
            component={withAuth(AsyncWizard)}
          />
          <Route exact path="/flexplan" component={withAuth(AsyncFlexplan)} />
          <Route
            exact
            path="/toelichting-ambucare"
            component={AsyncExplainAmbucare}
          />
          <Route
            exact
            path="/handleiding-ambucare"
            component={AsyncManualAmbucare}
          />
          <Route
            exact
            path="/profel-family-info"
            component={AsyncProfelFamily}
          />
          <Route
            exact
            path="/toelichting-pensioensparen"
            component={AsyncExplainPension}
          />
          <Redirect from="/" to="/info" />
        </Switch>
      </Suspense>
      <Footer />
    </div>
  );
};
