import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Container, Flex, hex2rgb, Icon, media, Text } from 'modules/ui';

export const SideBar = styled.div`
  width: 200px;
  ${media.md`width: 50px;`};
  background-color: ${p => p.theme.white};
  box-shadow: 0px 0px 10px 2px #f1f1f1;
  position: relative;
  color: ${p => p.theme.sidebarText};
  font-weight: 600;
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
`;

export const BookmarkIcon = styled(Icon)`
  position: absolute;
  top: -1px;
  right: 20px;
  color: ${p => p.theme.secondary};
`;

export const WrapperContainer = styled(Container)`
  display: grid;
  grid-template-columns: 200px 1fr;
  ${media.md`grid-template-columns: 1fr;`};
  grid-gap: 20px;
  padding: 20px 15px;
`;

export const ItemText = styled(Text)`
  text-decoration: none;
  word-break: break-word;
  hyphens: auto;
  color: ${p => p.theme.sidebarText};
`;

export const ItemIcon = styled(Icon)<{ spin?: boolean }>`
  margin-right: 8px;
  ${media.md`margin-right: 0`};
  min-width: 15px;
  ${p => !p.color && `color: ${p.theme.sidebarIcon}`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemLink = styled(Link)<{
  variant: { last?: boolean; active?: boolean };
}>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px;
  transition: all 0.3s;
  ${media.md`
    padding: 15px 20px;
    justify-content: center;
  `};
  margin: 4px 0;
  ${p => p.variant.last && 'margin-top: 50px'};
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  ${p =>
    p.variant.active &&
    css`
      background-color: ${hex2rgb(p.theme.primary, 0.2)};
      &:before {
        content: '';
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${p.theme.primary};
      }
      i {
        color: ${p.theme.primary};
      }
      & ${ItemText} {
        color: ${p.theme.primary};
      }
    `};
  &:hover {
    background-color: ${p => hex2rgb(p.theme.primary, 0.2)};
    cursor: pointer;
    i {
      color: ${p => p.theme.primary};
    }
  }
  &:hover:before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${p => p.theme.primary};
  }
  &:hover ${ItemText} {
    color: ${p => p.theme.primary};
  }
`;
ItemLink.defaultProps = {
  variant: {},
};

export const UserName = styled(Flex)`
  padding: 15px;
  ${media.md`
    padding: 5px;
  `};
`;
